<template>
  <div class="page-bottom">
    <div class="pb-left">
      <div class="pbl-t">山东路通石化有限公司</div>
      <div class="pbl-m">
        地址：山东省济南市历下区经十路13777号中润世纪广场9栋
      </div>
      <div class="pbl-b">客服电话:15562544000</div>
    </div>
    <div class="pb-middle">
      <div class="pbm-t">关于我们</div>
      <div class="pbm-item">内容合作</div>
      <div class="pbm-item">商务合作</div>
      <div class="pbm-item">法律声明</div>
      <div class="pbm-item">技术支持</div>
    </div>
    <div class="pb-right">
      <img src="../static/hWechart.jpg" class="pbr-img" />
      <div class="pbr-txt">官方微信</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {},
};
</script>

<style scoped lang="scss">
.page-bottom {
  color: #fff;
  background-color: #000000;
  font-family: Source Han Sans CN;
  display: flex;
  padding: 30px 200px;
  .pb-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    .pbl-t {
      margin-bottom: 10px;
      font-size: 22px;
    }
    .pbl-m {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .pbl-b {
    }
  }
  .pb-middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    .pbm-t {
      font-size: 22px;
    }
    .pbm-item {
      margin-top: 5px;
    }
  }
  .pb-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    .pbr-img {
      height: 92px;
      width: 92px;
    }
    .pbr-txt {
      margin-top: 20px;
    }
  }
}
</style>
