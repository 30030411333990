import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: () =>
      import("../views/AboutUs.vue"),
  },
  {
    path: "/contactus",
    name: "ContactUs",
    component: () => import("../views/ContactUs.vue"),
  },
  {
    path: "/newscenter",
    name: "NewsCenter",
    component: () => import("../views/NewsCenter.vue"),
  },
  {
    path: "/partybuilding",
    name: "PartyBuilding",
    component: () => import("../views/PartyBuilding.vue"),
  },
  {
    path: "/productsshow",
    name: "ProductsShow",
    component: () => import("../views/ProductsShow.vue"),
  },
  {
    path: "/salesnetwork",
    name: "SalesNetwork",
    component: () => import("../views/SalesNetwork.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
