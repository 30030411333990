<template>
  <div class="home" id="home">
    <!--  -->
    <div class="one-bg-box">
      <div class="obb-box">
        <div class="obb-tit">山东路通石油</div>
        <div class="obb-txt">创新突破稳定品质</div>
        <div class="obb-txt">落实管理提高效率</div>
        <div class="obb-bottom-box">
          <div class="obb-bottom-b-icon obi-l"></div>
          <div class="obb-bottom-b-middle">用户满意是我们永恒的追求</div>
          <div class="obb-bottom-b-icon obi-r"></div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="news-box">
      <div class="nb-top">
        <div class="nbt-left">热门关键词:</div>
        <div class="nbt-middle">92#</div>
        <div class="nbt-middle">95#</div>
        <div class="nbt-middle">98#</div>
        <div class="nbt-middle">食品</div>
        <div class="nbt-middle">电信服务</div>
        <div class="nbt-right">
          <input type="text" />
          <img src="../static/homeseach.png" alt="" />
        </div>
      </div>
      <div class="nb-bottom">
        <div class="nbb-top">
          <div class="nbbt-line"></div>
          <div class="nbbt-tit">新闻中心</div>
        </div>
        <div class="nbb-bottom">
          <div class="nbbb-left">
            <!-- News每一项 -->
            <div class="nbbbl-item flex">
              <div class="nbbbl-il">2021-04-21</div>
              <a href="" class="nbbbl-ir"
                >路通公司开展“弘扬革命精神,看见噶看来高科技垃圾开了个就开始</a
              >
            </div>
            <!-- News每一项 -->
            <div class="nbbbl-item flex">
              <div class="nbbbl-il">2021-04-21</div>
              <a href="" class="nbbbl-ir"
                >路通公司开展“弘扬革命精神,看见噶看来高科技垃圾开了个就开始</a
              >
            </div>
            <!-- News每一项 -->
            <div class="nbbbl-item flex">
              <div class="nbbbl-il">2021-04-21</div>
              <a href="" class="nbbbl-ir"
                >路通公司开展“弘扬革命精神,看见噶看来高科技垃圾开了个就开始</a
              >
            </div>
            <!-- News每一项 -->
            <div class="nbbbl-item flex">
              <div class="nbbbl-il">2021-04-21</div>
              <a href="" class="nbbbl-ir"
                >路通公司开展“弘扬革命精神,看见噶看来高科技垃圾开了个就开始</a
              >
            </div>
            <!-- News每一项 -->
            <div class="nbbbl-item flex">
              <div class="nbbbl-il">2021-04-21</div>
              <a href="" class="nbbbl-ir"
                >路通公司开展“弘扬革命精神,看见噶看来高科技垃圾开了个就开始</a
              >
            </div>
          </div>
          <div class="nbbb-right">
            <div class="nbbbr-youjia">
              <div class="nbbbry-top">今日油价</div>
              <div class="nbbbry-bottom">
                <!-- 油价每一项 -->
                <div class="nbbbryb-item">
                  <div class="nbbbrybi-l">92#</div>
                  <div class="nbbbrybi-r">6.23</div>
                </div>
                <!-- 油价每一项 -->
                <div class="nbbbryb-item">
                  <div class="nbbbrybi-l">95#</div>
                  <div class="nbbbrybi-r">6.23</div>
                </div>
                <!-- 油价每一项 -->
                <div class="nbbbryb-item">
                  <div class="nbbbrybi-l">98#</div>
                  <div class="nbbbrybi-r">6.23</div>
                </div>
                <!-- 油价每一项 -->
                <div class="nbbbryb-item">
                  <div class="nbbbrybi-l">柴油</div>
                  <div class="nbbbrybi-r">6.23</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="aboutus-box">
      <div class="ab-left">
        <img src="../static/haboutusimg.jpg" alt="" class="abl-img" />
      </div>
      <div class="ab-right">
        <div class="abr-tit">路通的故事</div>
        <div class="abr-txt">
          山东路通石化有限公司于2012年05月09日成立。
          法定代表人王前，公司经营范围包括：汽油、柴
          油、加气站、石油添加剂、预包装食品零售（限
          分支机构经营，有效期限以许可证为准）；
        </div>
        <div class="abr-button"></div>
      </div>
    </div>
    <!--  -->
    <div class="part-building-box">
      <div class="pbb-tit-en">Party mass center</div>
      <div class="pbb-tit">
        <div class="pbbt-line"></div>
        <div class="pbbt-tit">党群建设</div>
      </div>
      <div class="pbb-content">
        <div class="pbbc-left">
          <li>路通公司党支部扎实开展“学党史 悟思想 办实事”</li>
          <li>公司党委召开民主生活会</li>
          <li>路通公司开展“弘扬革命精神 传承红色基因” 主题</li>
          <li>紧绷“安全弦”、防患于未“燃”， 路通公司开展安</li>
          <li>“夯基础 提技能 学知识 练本领 ”— 路通公司急救知识</li>
          <li>中共中央政治局进行第三十一次集体学习</li>
        </div>
        <img src="../static/hpartimg.png" class="pbbc-right" />
      </div>
    </div>
    <!--  -->
    <div class="products-box">
      <div class="pb-tit">产品中心</div>
      <div class="pb-img-top">
        <img src="../static/hpl.png" alt="" class="pbit-item" />
        <img src="../static/hpm.png" alt="" class="pbit-item" />
        <img src="../static/hpr.png" alt="" class="pbit-item" />
      </div>
      <div class="pb-img-bottom">
        <img src="../static/homeprobl.png" alt="" class="pbib-item" />
        <img src="../static/homeprobm.png" alt="" class="pbib-item" />
        <img src="../static/homeprobr.png" alt="" class="pbib-item" />
      </div>
    </div>
    <!--  -->
    <div class="youzhanfenbu-box">
      <div class="yb-en-tit">Distribution of service stations</div>
      <div class="yb-tit-box">
        <div class="ybb-line"></div>
        <div class="ybb-tit">油站分布</div>
      </div>
      <img class="yb-youzhan-img" src="../static/hyouzhanfenbuimg.png">
    </div>
    <page-bottom></page-bottom>
  </div>
</template>
<script>
import PageBottom from "../components/PageBottom.vue"
export default {
  components: {
    PageBottom,
  },
  data() {
    return {};
  },
  created() {
    console.log(document.getElementById('home').offsetHeight)
    
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
a {
  color: #000000;
}
li:hover {
  cursor: pointer; /*鼠标悬停变小手*/
  border-bottom: 1px solid #000000;
}
a:hover {
  color: #2e86a9;

  text-decoration: none;
}
.flex {
  display: flex;
}
.home {
  .one-bg-box {
    height: 350px;
    background: url("../static/homeBg.png") no-repeat;
    background-size: 100% 100%;
    .obb-box {
      position: absolute;
      left: 100px;
      top: 200px;
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .obb-tit {
        margin-top: 20px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #e40316;
        opacity: 1;
      }
      .obb-txt {
        margin-top: 10px;
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        opacity: 1;
      }
      .obb-bottom-box {
        display: flex;
        .obb-bottom-b-middle {
          padding: 0 5px;
          margin: 0 5px;
          display: flex;
          align-items: center;
          height: 30px;
          background-color: #ffffff;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
        .obb-bottom-b-icon {
          width: 60px;
          height: 30px;
          background-size: 100% 100%;
        }
        .obi-l {
          background-image: url("../static/homeiconleft.png");
        }
        .obi-r {
          background-image: url("../static/homeiconright.png");
        }
      }
    }
  }
  //2
  .news-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    .nb-top {
      display: flex;
      align-items: center;
      .nbt-left {
        margin-right: 20px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #666666;
        opacity: 1;
      }
      .nbt-middle {
        margin-right: 15px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #999999;
        opacity: 1;
      }
      .nbt-right {
        border-radius: 6px;
        margin-left: 100px;
        display: flex;
        align-items: center;
        height: 40px;
        border: 1px solid #737272;
        input {
          border-radius: 6px;
          height: 40px;
          border: none;
          font-size: 24px;
        }
        img {
          border-radius: 0 6px 6px 0;
          height: 40px;
        }
      }
    }
    .nb-bottom {
      .nbb-top {
        width: 600px;
        .nbbt-line {
          margin-top: 50px;
          width: 100%;
          border-bottom: 1px solid #000000;
        }
        .nbbt-tit {
          height: 40px;
          text-align: center;
          top: -20px;
          left: 200px;
          position: relative;
          width: 200px;
          background-color: #fff;
          font-size: 25px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #2e86a9;
          opacity: 1;
        }
      }
      .nbb-bottom {
        display: flex;
        .nbbb-left {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-right: 100px;
          width: 400px;
          .nbbbl-item {
            margin-top: 10px;
            .nbbbl-il {
              margin-right: 20px;
              white-space: nowrap;
            }
            .nbbbl-ir {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .nbbb-right {
          .nbbbr-youjia {
            .nbbbry-top {
              border-radius: 10px 10px 0 0;
              text-align: center;
              padding: 8px 0;
              background-color: red;
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #ffffff;
              opacity: 1;
            }
            .nbbbry-bottom {
              display: flex;
              flex-direction: column;
              padding: 10px 40px;
              border-radius: 0 0 10px 10px;
              background-color: #e5e5e5;
              .nbbbryb-item {
                margin-top: 7px;
                display: flex;
                .nbbbrybi-l {
                  min-width: 50px;
                  text-align: center;
                  margin-right: 5px;
                  padding: 2px 5px;
                  background: #2c78e9;
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #ffffff;
                  opacity: 1;
                }
                .nbbbrybi-r {
                  min-width: 50px;
                  text-align: center;
                  padding: 2px 5px;
                  background-color: #ffffff;
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #f63535;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
  .aboutus-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px;
    background-image: url("../static/homeaboutus.png");
    .ab-left {
      margin-right: 100px;
      position: relative;
      height: 260px;
      width: 260px;
      border: 1px solid #fff;
      .abl-img {
        position: absolute;
        top: -30px;
        left: 30px;
        z-index: 99999;
        height: 260px;
        width: 260px;
      }
    }
    .ab-right {
      width: 30%;
    }
  }

  .part-building-box {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pbb-tit-en {
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #2e86a9;
      opacity: 1;
    }
    .pbb-tit {
      width: 600px;
      .pbbt-line {
        margin-top: 50px;
        width: 100%;
        border-bottom: 1px solid #000000;
      }
      .pbbt-tit {
        height: 40px;
        text-align: center;
        top: -20px;
        left: 200px;
        position: relative;
        width: 200px;
        background-color: #fff;
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #2e86a9;
        opacity: 1;
      }
    }
    .pbb-content {
      display: flex;
      align-items: center;
      justify-content: center;
      .pbbc-left {
        margin-right: 100px;
        width: 80%;
        li {
          margin-top: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .pbbc-right {
        height: 260px;
        width: 280px;
      }
    }
  }
  .products-box {
    background-image: url("../static/hproductimg.png");
    padding: 50px 0;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pb-tit {
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      opacity: 1;
    }
    .pb-img-top {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      width: 70%;
      .pbit-item {
        margin-right: 10px;
        width: 190px;
        height: 110px;
        background: rgba(0, 0, 0, 0);
      }
    }
    .pb-img-bottom {
      margin-top: 5px;
      display: flex;
      .pbib-item {
        margin-right: 10px;
        width: 190px;
        height: 145px;
        background: rgba(0, 0, 0, 0);
      }
    }
  }
  .youzhanfenbu-box {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .yb-en-tit {
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #2e86a9;
      opacity: 1;
    }
    .yb-tit-box {
      width: 600px;

      .ybb-line {
        margin-top: 50px;
        width: 100%;
        border-bottom: 1px solid #000000;
      }
      .ybb-tit {
        height: 40px;
        text-align: center;
        top: -20px;
        left: 200px;
        position: relative;
        width: 200px;
        background-color: #fff;
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #2e86a9;
        opacity: 1;
      }
    }
    .yb-youzhan-img{
      height: 500px;
      width: 500px;
    }
  }
}
</style>

