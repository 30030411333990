<template>
  <div id="app">
    <div class="nav-box">
      <div class="nav-logo" @click="goNav('/', 0)">
        <img src="./static/lutongLogo.png" class="logo-img">
        <div class="logo-tit">路通石化</div>
      </div>
      <div :class="navSelectIndex == 0 ? 'nav-item nav-select' : 'nav-item'" @click="goNav('/', 0)">首页</div>
      <div :class="navSelectIndex == 1 ? 'nav-item nav-select' : 'nav-item'" @click="goNav('/aboutus', 1)">关于我们</div>
      <div :class="navSelectIndex == 2 ? 'nav-item nav-select' : 'nav-item'" @click="goNav('/productsshow', 2)">产品展示</div>
      <div :class="navSelectIndex == 3 ? 'nav-item nav-select' : 'nav-item'" @click="goNav('/newscenter', 3)">新闻中心</div>
      <div :class="navSelectIndex == 4 ? 'nav-item nav-select' : 'nav-item'" @click="goNav('/salesnetwork', 4)">销售网络</div>
      <div :class="navSelectIndex == 5 ? 'nav-item nav-select' : 'nav-item'" @click="goNav('/partybuilding', 5)">党群建设</div>
      <div :class="navSelectIndex == 6 ? 'nav-item nav-select' : 'nav-item'" @click="goNav('/contactus', 6)">联系我们</div>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      navSelectIndex: 0,
    }
  },
  created() {
    this.navSelectIndex = 0
    console.log(this.navSelectIndex)
  },
  methods: {
    //点击导航栏
    goNav(type, selIndex) {
      this.navSelectIndex = selIndex;
       this.$router.push(type);
    },
    seeShare(){
      let routeUrl = this.$router.resolve({
        path: "/about",
        query: {id:96}
      });
      window.open(routeUrl.href, '_blank');
    }
  },
}
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
.nav-select{
  color: #E40316;
  border-bottom: 2px solid #E40316;
}
.nav-box {
  cursor: pointer;  /*鼠标悬停变小手*/
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  .nav-logo {
    display: flex;
    align-items: center;
    .logo-img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    .logo-tit {
      margin-right: 68px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #E40316;
      opacity: 1;
    }
  }
  .nav-item {
    cursor: pointer;  /*鼠标悬停变小手*/
    margin-right: 20px;
    font-size: 14x;
    font-family: Source Han Sans CN;
    font-weight: 400;
    opacity: 1;
  }
}
</style>
